<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="$t('project.menu.unitList')"
    class="px-5 py-3"
  >
    <base-add-button
      :permissions="permissions"
      :basePath="basePath"
      :title="$t('unitCategory.add')"
      :params="addBtnParams"
    >
    </base-add-button>
    <v-simple-table v-if="items && items.length > 0">
      <thead>
        <tr>
          <th class="text-center primary--text">
            {{ $t('general.actions') }}
          </th>
          <!--          <th class="primary&#45;&#45;text">-->
          <!--            ID-->
          <!--          </th>-->
          <th class="primary--text">
            {{ $t('project.unitType.block') }}
          </th>
          <th class="primary--text">
            {{ $t('project.unitType.number') }}
          </th>
          <th class="primary--text">
            {{ $t('project.unitType.price') }}
          </th>
          <th class="primary--text">
            {{ $t('project.unitType.buildingDirection') }}
          </th>
          <th class="primary--text">
            {{ $t('unit.soldStatus') }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="item in items" :key="item.uuid">
          <td>
            <div class="d-flex justify-center align-center">
              <base-edit-button
                :permissions="permissions"
                :basePath="basePath"
                :id="item.uuid"
              ></base-edit-button>
              <base-delete-button
                :permissions="permissions"
                :id="item.uuid"
                :showIcon="true"
                @deleteAction="deleteAction"
              ></base-delete-button>
            </div>
          </td>
          <!--          <td>{{ index + 1 }}</td>-->
          <td>{{ item.block }}</td>
          <td>{{ item.number }}</td>
          <td>{{ $n(item.price, 'currency', 'id-ID') }}</td>
          <td>{{ item.building_direction }}</td>
          <td>{{ item.is_sold ? $t('unit.sold') : $t('unit.notSold') }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <pagination
      :push-state="false"
      :meta="meta"
      @changePage="changePage"
      :maxVisibleButtons="5"
      v-if="meta"
    />
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
const Pagination = () => import('@/components/utils/fractal-pagination');

export default {
  props: ['items', 'meta', 'permissions', 'unitTypeUuid', 'unitCategoryId', 'projectUuid'],
  components: {
    Pagination,
  },
  computed: {
    ...mapState({
      basePath: (state) => state.unit.basePath,
    }),

    addBtnParams() {
      if (this.unitTypeUuid) {
        return {
          project_unit_type_uuid: this.unitTypeUuid,
        };
      } else if (this.unitCategoryId) {
        return {
          unit_category_id: this.unitCategoryId,
        };
      } else if (this.projectUuid) {
        return {
          project_uuid: this.projectUuid,
        };
      } else {
        return {};
      }
    },
  },
  methods: {
    changePage(page) {
      this.$emit('changePage', page);
    },
    async deleteAction(uuid) {
      try {
        await this.$store.dispatch('unit/delete', uuid);
        await this.$store.dispatch('unit/removeUnitListByUuid', uuid);
        this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('project.unit.delete.successMsg'),
          'success',
        );
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
  },
};
</script>
